import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthState } from "_redux/auth/actions";
import { setUserData } from "_redux/user/actions";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { login } from "util/auth";
import ctms_logo from "assets/logo.png";
import styles from "./index.module.css";
import router from "views/routes";

const error_message = "Sorry, your login was not correct.";

export default function SignIn() {
  const [is_loading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [show2fa, setShow2fa] = useState(false);
  const [email, setEmail] = useState("");
  const [_2faCode, set2faCode] = useState("");
  const [googleError, setGoogleError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = () => {
    if (is_loading) return;
    setIsLoading(true);
    if (_2faCode && _2faCode.length !== 6) {
      setGoogleError("Code is the wrong length.");
      setIsLoading(false);
      return;
    }
    login(email, password, _2faCode || null)
      .then((ret) => {
        if (ret.data.enable_2fa) {
          setShow2fa(true);
          setIsLoading(false);
        } else {
          dispatch(setAuthState(true));
          if (ret.data.user_data) {
            dispatch(setUserData(ret.data.user_data));
          }
          navigate("/files");
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        if (!show2fa) {
          setPassword("");
        } else {
          setGoogleError("Invalid code, please try again.");
        }
        setErrorMessage(error_message);
      });
  };

  const handleCancel2fa = () => {
    setShow2fa(false);
    setErrorMessage("");
    setPassword("");
    set2faCode(null);
    setGoogleError(null);
  };

  const handleFieldChange = (key) => (e) => {
    if (key === "email") setEmail(e.target.value);
    else if (key === "password") setPassword(e.target.value);
    setErrorMessage(null);
  };

  const onKeyDown = (key) => {
    if (key.key === "Enter") {
      handleLogin();
    }
  };

  const onKeyDown2fa = (key) => {
    if (key.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div className={styles.wrapper}>
      {!show2fa ? (
        <div className={styles.login_card}>
          <div className={styles.logo_wrapper}>
            <img src={ctms_logo} width="200px" className={styles.logo} />
          </div>
          <div className={styles.margin_b_1}>
            <TextField
              label="Email"
              variant="standard"
              onChange={handleFieldChange("email")}
              onKeyDown={onKeyDown}
              inputProps={{ style: { textTransform: "lowercase" } }}
            />
          </div>
          <div className={styles.margin_b_2}>
            <TextField
              id="standard-basic"
              label="Password"
              variant="standard"
              type="password"
              onChange={handleFieldChange("password")}
              onKeyDown={onKeyDown}
              value={password}
            />
          </div>
          <LoadingButton
            variant="contained"
            loading={is_loading}
            onClick={handleLogin}
          >
            SIGN IN
          </LoadingButton>
          {errorMessage ? <div>{errorMessage}</div> : null}
          {/*
          <div
            className={styles.forgot_password}
            onClick={() => router.navigate("/password_reset")}
          >
            Forgot password?
          </div>*/}
        </div>
      ) : null}
      {show2fa ? (
        <div className={styles.login_card}>
          <img src={ctms_logo} width="80px" className={styles.logo} />
          <div className={styles._2fa_message}>
            Please enter the 6-digit code you were just emailed.
          </div>
          <div>
            <TextField
              placeholder=""
              value={_2faCode}
              onChange={(e) => set2faCode(e.target.value)}
              autoComplete="off"
              onKeyDown={onKeyDown2fa}
              inputProps={{ maxLength: 6 }}
              sx={{
                "& .MuiInputBase-input": {
                  padding: "5px",
                  textAlign: "center",
                  width: "100px",
                },
              }}
            />
          </div>
          <div className={styles._2fa_button_wrapper}>
            <LoadingButton
              variant="contained"
              loading={is_loading}
              onClick={handleLogin}
              className={styles._2fa_button}
            >
              SUBMIT
            </LoadingButton>
            {googleError ? (
              <div className={styles._2fa_error}>{googleError}</div>
            ) : null}
          </div>
          <div className={styles.cancel_button} onClick={handleCancel2fa}>
            Cancel
          </div>
        </div>
      ) : null}
    </div>
  );
}
