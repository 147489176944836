import { Button } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import styles from "./selected_file_buttons.module.css";
import { get } from "util/requests";
import axios from "axios";
import { api_url } from "config";

export default function SelectedFileButtons(props) {
  const { selectedFiles, setDeleteAssetIsOpen, setRenameModalIsOpen } = props;
  const show_rename_button = () => {
    return (
      selectedFiles.length === 1 && selectedFiles[0].type !== "site_folder"
    );
  };
  const show_download_button = () => {
    return selectedFiles.length === 1 && selectedFiles[0].type === "file";
  };
  const show_delete_button = () => {
    return selectedFiles.length === 1 && selectedFiles[0].type === "file";
  };

  if (selectedFiles.length === 0) return null;

  const download = () => {
    let uuid = selectedFiles[0].uuid;

    // console.log(two);
    // window.open(, '_blank', 'download=google.html')
    // Create blob link to download
    // const url = window.URL.createObjectURL(`${api_url}/download_file/${uuid}`);
    const url = `${api_url}/download_file/${uuid}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", selectedFiles[0].name);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };
  return (
    <div>
      {show_delete_button() ? (
        <Button
          className={styles.button}
          onClick={() => setDeleteAssetIsOpen(true)}
        >
          <DeleteOutlineOutlinedIcon
            sx={{ fontSize: "18px", marginBottom: "2px" }}
          />
          <div>Delete</div>
        </Button>
      ) : null}
      {show_download_button() ? (
        <Button className={styles.button} onClick={download}>
          <DownloadOutlinedIcon
            sx={{ fontSize: "20px", marginBottom: "0px" }}
          />
          Download
        </Button>
      ) : null}

      {show_rename_button() ? (
        <Button
          className={styles.button}
          onClick={() => {
            setRenameModalIsOpen(true);
          }}
        >
          <EditOutlinedIcon
            sx={{ fontSize: "18px", marginBottom: "2px", marginRight: "2px" }}
          />
          Rename
        </Button>
      ) : null}
    </div>
  );
}
